<template>
  <div>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row
              align-v="center"
              class="mb-2 pb-1"
              style="border-bottom: 1px solid #ddd"
            >
              <b-col
                class="text-center"
                style="cursor: pointer"
                cols="auto"
                @click="$router.go(-1)"
              >
                <b-col cols="12">
                  {{ $t("back") }}
                </b-col>
                <b-col cols="12">
                  <feather-icon
                    v-if="$i18n.isRTL"
                    icon="ArrowRightIcon"
                    size="14"
                  />
                  <feather-icon
                    v-else
                    icon="ArrowLeftIcon"
                    size="14"
                  />
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-table
                  no-border-collapse
                  responsive
                  striped
                  hover
                  :fields="accountsfields"
                  :items="items"
                />
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="auto">
                <b-pagination
                  v-if="pagination.total > pagination.per_page"
                  v-model="pagination.page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  align="fill"
                  size="sm"
                  class="mt-2 mb-1"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import PannelIbApis from '@/modules/IB/services/apis'

const ib = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
  },
  data() {
    return {
      show: true,
      items: [],
      transferModal: false,
      transferType: [1],
      transferForm: {
        amount: '',
        description: '',
        code: '',
        memberId: '',
      },
      pagination: {
        page: 1,
        per_page: 5,
        total: 0,
      },
      accountsfields: [
        { key: 'account', label: this.$t('account_id') },
        { key: 'balance', label: this.$t('balance') },
        { key: 'type', label: this.$t('type') },
        { key: 'deposit', label: this.$t('deposit') },
        { key: 'withdrawal', label: this.$t('withdrawal') },
        { key: 'date', label: this.$t('date') },
      ],
    }
  },
  computed: {
    page() {
      return this.pagination.page
    },
  },
  watch: {
    page(val) {
      if (val) {
        this.getData()
      }
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      delete this.pagination.total
      await ib.metatraderAccountHistory(this.$route.params.userID, this.$route.params.accid, { ...this.pagination }).then(res => {
        this.items = res.data.results.data
        this.pagination.total = res.data.results.pagination.total
      })
      this.show = false
    },
  },

}
</script>
  <style>

  </style>
